.Footer {
    p{
        margin: 0;
    }
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #343348;
    color: white;
    text-align: center;
    font-size: 12px;
    padding: 7px 0;
    display: flex;
    justify-content: space-around;
 }

 @media only screen and (max-width: 700px) {
    .Footer{
        flex-direction: column;
    }
}