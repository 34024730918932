.JobDescription{
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
    margin-top: 20px;
    border-radius: 5px;

    padding: 10px 20px;
}

.JobPageWrapper{
    display: grid;
    grid-template-columns: 250px auto;
    gap: 20px;
    margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
    .JobPageWrapper{
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
    }
}