.Title{
    color: grey;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
}

.SubTitle{
    color: grey;
    font-size: 15px;
    margin-top: 20px;
}

.CardVacancy{
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.45);
    border-radius: 10px;
    position: relative;
    padding: 10px;
    margin-bottom: 40px;

    .Divider{
        border-top: 1px solid rgba(0,0,0,.1);
        margin: 12px;
    }

    .Title{
        margin: 10px 0;
        text-align: center;
        color: #5D6EBE;
        font-size: 17px;
        font-weight: bold;
    }

    p{
        margin: 5px 0;
        padding-left: 20px;
        font-size: 13.5px;
        color: grey;
        span{
            font-weight: bold;
        }
    }

    .Price{
        margin: -10px;
        font-size: 25px;
        font-weight: bolder;
        color: #5D6EBE;
        span{
            font-size: 15px;
            font-weight: 100;
        }
    }

    .ApplyButton{
        background-color: #343349;
        border: 2px solid #343349;
        border-radius: 4px;
        color: #fff;
        padding: 4px 25px;
        font-size: 15px;
        font-weight: 400;
        margin-top: 15px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.ApplyButtonModal{
    margin: 10px 0;
    background-color: #343349;
    border: 2px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 15px;
    font-weight: 400;
}

.DialogRegister{
    p{
        padding: 0;
        margin: 0;
        color: grey;
        font-size: 15px;
    }
}

.CheckboxLabel{
    font-size: 12px;
    color: grey;

    span{
        color: #3CAADA;
        font-weight: 600;
    }
}

.AlreadyAccount{
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
    color: grey;
    span{
        color: #3CAADA;
        font-weight: bold;
    }
}

.RegisterButton{
    margin: 10px 0;
    background-color: #5D6EBE;
    border: 2px solid #5D6EBE;
    border-radius: 4px;
    color: #fff;
    padding: 8px 35px;
    font-size: 15px;
    font-weight: 400;
}

.SuccessMsgWrapper{
    padding: 50px;
    p{
        color: grey;
    }
    .PrimaryText{
        color: #5D6EBE;
        font-weight: 600;
    }
    
    .DangerText{
        font-weight: 600;
        color: red;
    }
}

.FileButton{
    border-radius: 5px;
    height: 40px;
    width: 100%;
    background-color: white;
    font-size: 14px;
    text-align: left;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.90);
}

.PartnerTitle{
    color: #5D6EBE;
    font-weight: bold;
    font-size: 19px;
    margin: 20px 0;
}

.PartnersWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 30px;
    img{
        width: 120px;
        border-radius: 50%;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
    }

    p{
        color: grey;
        font-size: 17px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 768px) {
    .PartnersWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        img{
            margin-top: 30px;
            width: 100%;
        }
    }
}