.ApplyModalTitle{
    padding: 0;
    margin: 0;
    font-size: 15px;
    color: #343349;
    text-transform: uppercase;
    font-weight: 600;
}

.TextDescription{
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: grey;

    span{
        cursor: pointer;
        color: #5D6EBE;
        font-weight: 600;
    }
}

.RegisterForm{
    margin: 20px 0;
}

.Divider{
    border-top: 1px solid rgba(0,0,0,.1);
}

.AddNewFormButtons{
    margin-top: 10px;
    display: flex;

    .ButtonText{
        cursor: pointer;

        span{
            font-size: 14px;
        }
    }
}

.ApplyButton{
    background-color: #343349;
    border: 2px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
}