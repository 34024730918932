.LabelValueWrapper{

    p{
        margin: 0;
        padding: 0;
    }

    .Label{
        font-size: 12px;
        color: #a7a9ac;
    }

    .Value{
        font-size: 15px;
        color: grey;
        font-weight: 600;
    }

    .Link{
        text-decoration: none;
        font-size: 14px;
    }
}