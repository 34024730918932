.SubmitButton{
    background-color: #343349;
    border: 1px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.SubmitButton:hover{
    border: 1px solid #343349;
    background-color: #fff;
    color: #343349;
}