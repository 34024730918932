.JobsWrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 20px;
}

.FiltersWrapper{
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
    border-radius: 5px;
    margin-top: 20px;

    .ClearFiltersText{
        text-transform: uppercase;
        font-size: 13px;
        display: flex;
        justify-content: end;
        cursor: pointer;
        color: grey;
        p{
            padding: 0;
            margin: 0;
        }
    }
}