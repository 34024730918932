.JobWrapper{
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
    margin-top: 20px;
    border-radius: 5px;
    height: 280px;

    text-align: center;
    padding-bottom: 10px;

    .CompanyJobLogo{
        max-width: 100%;
        max-height: 120px;
        padding: 20px;
    }

    .Divider{
        border-top: 1px solid rgba(0,0,0,.1);
    }

    .JobDescription{
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 0 5px;
    }

    .JobDescriptionPositionWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        padding: 0 5px;
    }

    .JobDescriptionLabel{
        padding: 0;
        margin: 0;
        font-size: 10px;
        color: #a7a9ac;
    }

    .JobDescriptionValue{
        padding: 0;
        margin: 0;
        font-size: 13px;
        color: grey;
    }

    .JobDescriptionPosition{
        padding: 0;
        margin-top: -10px;
        font-size: 14px;
        color: grey;
        text-transform: uppercase;
    }
}

.ApplyButton{
    background-color: #343349;
    border: 2px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
}

.ApplyButtonModal{
    background-color: #343349;
    border: 2px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 15px;
    font-weight: 400;
}

.JobWrapper:hover{
    background-color: #fff;
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.25);
    cursor: pointer;
}