.ProfileWrapper{
    margin-top: 20px;
    
    .MainProfileWrapperBox{
        position: relative;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
        padding: 10px;
        height: 500px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }
    }

    .SecondProfileWrapperBox{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
        padding: 10px;
        height: 500px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }
    }

    @media only screen and (max-width: 768px) {
        .SecondProfileWrapperBox {
            height: auto !important;
        }
    }

    .ProfileDescription{
        text-align: center;
        margin: 15px 0;
    }
}

.ProfileTabsWrapper{
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.DataProfileWrapper{
    position: relative;
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    margin-bottom: 70px;
    padding: 20px;

    .NoDataFound{
        padding: 0;
        margin: 0;
        text-align: center;
        color: grey;
        font-size: 15px;
        font-weight: 600;
    }

    .AddNewDataButton{
        position: absolute;
        right: 0;
        top: -15px;
        background-color: #343349;
        border: 1px solid #343349;
        border-radius: 4px;
        color: #fff;
        padding: 4px 25px;
        font-size: 14px;
        font-weight: 400;
    }

    .AddNewDataButton:hover{
        border: 1px solid #343349;
        background-color: #fff;
        color: #343349;
    }

    // Experiences
    .DataProfileItemWrapperExperience{
        position: relative;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }

        p{
            padding: 0;
            margin: 0;
        }

        .Position{
            color: grey;
            font-size: 18px;
            font-weight: bold;
        }

        .Company{
            color: #a7a9ac;
            font-size: 15px;
            font-weight: 600;
            span{
                font-size: 13px;
            }
        }

        .Dates{
            color: #a7a9ac;
            font-size: 14px;
            font-weight: 600;
        }

        .Description{
            padding: 20px 40px;
        }
        @media only screen and (max-width: 768px) {
            .Description {
                padding: 5px;
            }
        }
    }

    // Educations
    .DataProfileItemWrapperEducation{
        position: relative;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }

        .Divider{
            border-top: 1px solid rgba(0,0,0,.1);
            margin: 20px 50px;
        }
        @media only screen and (max-width: 768px) {
            .Divider {
                display: none;
            }
        }
    }

    // Trainings
    .DataProfileItemWrapperTraining{
        position: relative;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }

        .Divider{
            border-top: 1px solid rgba(0,0,0,.1);
            margin: 20px 50px;
        }
        @media only screen and (max-width: 768px) {
            .Divider {
                display: none;
            }
        }
    }

    // Languages
    .DataProfileItemWrapperLanguages{
        position: relative;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }

        .Divider{
            border-top: 1px solid rgba(0,0,0,.1);
            margin: 20px 50px;
        }
        @media only screen and (max-width: 768px) {
            .Divider {
                display: none;
            }
        }
    }

    // Skills
    .DataProfileItemWrapperSkill{
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }
    }

    // Licenses
    .DataProfileItemWrapperLicense{
        position: relative;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin: 20px 0;
        padding: 20px;

        .MoreIconButton{
            position: absolute;
            right: 0;
        }
    }
}

.MenuItems{
    li{
        font-size: 13px;
        padding: 2px 10px;
    }
}

// Small box
.SmallBox{
    position: relative;

    .MoreIconButton{
        position: absolute;
        top: 20px;
        right: 0;
    }
}

.ImageProfile{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 1px solid #E5E5E5;
}

.Button{
    background-color: #343349;
    border: 1px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}