.NavWrapper{
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding: 10px 0;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;

    .Nav{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Logo{
            width: 150px;
            cursor: pointer;
        }

        a{
            text-decoration: none;
            text-transform: uppercase;
            color: grey;
            font-weight: 600;
            font-size: 13px;
            margin-right: 20px;
        }

        @media only screen and (max-width: 500px) {
            a{
                text-decoration: none;
                text-transform: uppercase;
                color: grey;
                font-weight: 600;
                font-size: 12px;
                margin-right: 0px;
                margin-left: 10px;
            }
            .Logo{
                width: 100px;
                cursor: pointer;
            }
        }

        .Link{
            padding: 0;
            margin: 0;
            text-transform: uppercase;
            color: grey;
            font-weight: 600;
            font-size: 13px;
            margin-right: 20px;
            cursor: pointer;
        }
        @media only screen and (max-width: 768px) {
            .Link {
                margin-right: 0px !important;
                font-size: 12px;
            }
        }

        .NavDropdownMenu{
            padding: 0;
            text-transform: uppercase;
            color: grey;
            font-weight: 600;
            font-size: 13px;
        }
    }
}