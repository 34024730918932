.AuthPageWrapper{
    display: grid;
    grid-template-columns: 49% 1% 49%;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.Border{
    height: 100%;
    border-left: 1px solid #a7a9ac;
}

@media only screen and (max-width: 768px) {
    .AuthPageWrapper{
        display: grid !important;
        grid-template-columns: 100% !important;
        justify-content: center !important;
        margin-bottom: 30px;
    }
}

.FormTitle{
    margin-bottom: 20px;
    text-align: center;
    color: #5D6EBE;
    font-weight: 700;
    font-size: 18px;
}

.Button{
    background-color: #343349;
    border: 2px solid #343349;
    border-radius: 4px;
    color: #fff;
    padding: 4px 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
}

.Description{
    font-size: 14px;
    color: grey;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.FooterCardLogin{
    margin-bottom: 50px;
    p{
        padding: 0;
        margin: 0;
        margin-top: 20px;
        font-size: 14px;
        color: grey;
        font-weight: 600;
        text-align: center;
        span{
            color: #5D6EBE;
            cursor: pointer;
        }
    }
}